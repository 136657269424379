import { createRouter, createWebHistory } from "vue-router"
import { userStore } from '@/store/user'
import {message} from "ant-design-vue";
import {componentStore} from "@/store/component";
const routes = [
    {
        path: '/login',
        name: "登录",
        component: () => import('@/pages/login/login-page')
    },
    {
        path:'/error',
        name: "404,无效路径！",
        component: () => import('@/pages/error/error')
    },
    {
        path:'/tongjing_meeting',
        name: "会议室预约",
        component: () => import('@/pages/tongjing_meetting/tongjing_meetting')
    },
]
//图片仓库子路由
const imgReqChild=[
    {
        path: '/aliyun-imgReq',
        meta: {
            title: "阿里云OSS",
        },
        component: () => import('@/views/menu-imgReq/aliyun-imgReq/aliyun-imgReq'),
    },
    {
        path: '/aliyun-imgReq',
        meta: {
            title: "阿里云OSS",
        },
        component: () => import('@/views/menu-imgReq/tencent-imgReq/tencent-imgReq'),
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})
// 注册一个全局前置守卫 ,判断用户是否登录
router.beforeEach((to, from, next) => {
        if (to.meta.title) { //如果设置标题，拦截后设置标题
            document.title = to.meta.title
        }
    const user = userStore()
    const component=componentStore()
    if(to.matched.length !==0){
       return next()
    }else{
        if(user.getToken===undefined || user.getToken===null || user.getToken===''){
            message.error("请先登录！再访问该网页",1).then(res=>{
                if(res) router.push("/login")
            })
        }
        const menuList=user.formatMenu(user.getUserMenu)
        let isExistence=false;
        if(menuList.children.length!==0){
            menuList.children.forEach(menu=>{
                menu.meta.title=menu.name;
                if(menu.path===to.path){
                    component.setSelectItem(menu.name)
                    isExistence=true
                }
            })
            if(!isExistence){
                router.push("/error")
                return
            }
            menuList.children.forEach(item=>{
                console.log(item.name)
                if(item.name==='图片仓库'){
                    console.log(111111)
                    item.children=imgReqChild
                }
            })
            console.log(menuList)
            router.addRoute(menuList)
            return next({ ...to, replace: true })
        }
    }
    router.push("/error")
})

export default router
